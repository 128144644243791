import React from "react"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Slider from "react-slick";

class WeFocused extends React.Component {

  render() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      };

    return (

      <section className="we-focus">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Blockchain Development Services <span className="bluecolor">We Focus</span>
            </h2>
            <p className="text-center">
            Unlock the potential of blockchain development services to your enterprise with relevant blockchain technology that helps you meet the innovation with guaranteed performance. Explore more insights about the diverse blockchain development solutions we serve for your enterprise's success.
            </p>
            <Tab.Container id="left-tabs-example" defaultActiveKey="start" transition={false}>
                <Row>
                    <Col xl={4} lg={12} className="p-0 choose-tab">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="start">1. Blockchain Consulting Services</Nav.Link>
                        <Nav.Link eventKey="first">2. Public Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="second">3. Private Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="third">4. Custom Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="four">5. Fintech/DeFi Development</Nav.Link>
                        <Nav.Link eventKey="end">6. Trading Bot Development</Nav.Link>
                        <Nav.Link eventKey="five">7. NFT Development</Nav.Link>
                        <Nav.Link eventKey="six">8. Smart Contract Development</Nav.Link>
                        <Nav.Link eventKey="seven">9. DApps Development</Nav.Link>
                        <Nav.Link eventKey="eight">10. Tokenization</Nav.Link>
                        </Nav.Item>
                    </Nav>  
                    </Col>
                    <Col xl={8} lg={12} className="contents"> 
                    <Tab.Content>
                        <Tab.Pane eventKey="start"> 
                            <h3>Blockchain Consulting Services</h3>
                            <p>Coinsclone being a superior blockchain development company, has a team of experienced Blockchain Consultants. These experts will assist in navigating through the complexities of blockchain technology. From providing strategic business development advisories and complex feature integration to code-customizing smart contracts, our experts provide warrantied assistance to meet future demands.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="first"> 
                            <h3>Public Blockchain Development</h3>
                            <p>Coinsclone provides reliable services for public blockchain development that help startups and enterprises experience quicker access, secure storage, and blockchain data management. Public blockchains are permissionless and managed to provide unlimited access in a hassle-free manner.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <h3>Private Blockchain Development</h3>
                            <p>Private blockchain development unlocks complete ownership and control over the enterprise operations maintaining the data security. Permissioned blockchains have niche-based functionalities and higher-end security protocols that only allow recognized access while ensuring privacy and security.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <h3>Custom Blockchain Development</h3>
                            <p>Custom blockchain development is the process of designing, developing & customizing the blockchain software to suit your business requirements. It comes with enormous advantages like cost-efficiency, customization, speed, and easy access. Custom blockchains help businesses maintain reliability, credibility, and blockchain resources effectively under secure access. </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four">
                            <div>
                            <Slider {...settings}>
                                <div className="sliding">
                                    <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/crypto-exchange.png" alt="crypto exchange development" width={65} height={65} />
                                    <h3><a href="https://www.coinsclone.com/cryptocurrency-exchange-development-company/">Cryptocurrency Exchange Development</a></h3>
                                    <p><a href="https://www.coinsclone.com/cryptocurrency-exchange-development-company/" style={{color:"#006cfa"}}>Cryptocurrency Exchange Development</a> aids in creating a smart and unique centralized, decentralized, and hybrid exchange to serve the needs of novice and expert traders. Get your crypto exchange support in mobile and web platforms packed with multiple trading options, security protocols, and add-on modules.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/defi-stack.png" alt="DeFi Staking" width={65} height={65} />
                                    <h3>DeFi Staking</h3>
                                    <p>Explore the unparalleled benefits of <a href="https://www.coinsclone.com/defi-staking-platform-development/" style={{color:"#006cfa"}}>DeFi Staking Platform Development</a> with popular blockchains like Ethereum, Tron, Solana, Polygon, and more. Level up your user interaction with industry-demanding features and graded security protocols that come with DeFi staking development. </p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/defi-yield.png" alt="DeFi Yield Farming" width={65} height={65} />
                                    <h3>DeFi Yield Farming</h3>
                                    <p>DeFi Yield Farming Platform Development company assures the users with a high-performance yielding platform that is aligned with the right tech stacks and blockchain technology. Establish a hassle-free yield farming platform like Uniswap and AAVE that is backed with immutable smart contract functionalities.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/defi-lending.png" alt="DeFi Lending" width={65} height={65} />
                                    <h3>DeFi Lending</h3>
                                    <p>Stay ahead of market competition with the DeFi Lending Platform development company that prioritizes user-demanding features and protective measures. DeFi lending solution contains key components like lending modules, crypto wallets, and flash loans for a unique DeFi lending platform development. </p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/crypto-wallet.png" alt="Cryptocurrency Wallet" width={65} height={65} />
                                    <h3>Cryptocurrency Wallet Development</h3>
                                    <p><a href="https://www.coinsclone.com/cryptocurrency-wallet-development-company/" style={{color:"#006cfa"}}>Cryptocurrency Wallet Development</a> is the process of creating a fully-featured crypto wallet that enables your users to control their cryptocurrency to have smooth digital transactions. Chase the opportunities with diverse use cases like DeFi staking, cryptocurrency trading, and more with cryptocurrency wallet solutions.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/crypto-payment.png" alt="Cryptocurrency Payment" width={65} height={65} />
                                    <h3>Cryptocurrency Payment Gateway Development</h3>
                                    <p><a href="https://www.coinsclone.com/cryptocurrency-payment-gateway/" style={{color:"#006cfa"}}>Crypto Payment Gateway Development</a> refers to creating a feature-rich payment processing platform for the users to accept payments of multi-cryptocurrency without any hindrance. It is capable of supplying seamless transactions while keeping security intact & upgrades with a custom cryptocurrency payment gateway solution.</p>
                                </div>
                            </Slider>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="five">
                            <Slider {...settings}>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/nft-game.png" alt="NFT Development" width={65} height={65} />
                                    <h3>NFTs for Gaming/Metaverse</h3>
                                    <p>Explore the cutting-edge NFT Development Company for gaming that offers a unique gaming experience as per industry trends. Integrate technically flexible NFTs into the gaming/Metaverse space with designated tools to increase gaming activities and players' contributions without leaving the harmony of the NFT ecosystem.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/nft-smart.png" alt="NFT Smart Contract" width={65} height={65} />
                                    <h3>NFT Smart Contract</h3>
                                    <p>NFT Smart Contract Development ensures your NFTs are isolated from data breaches and security vulnerabilities. With an NFT Smart Contract solution, prioritize the protective measures like freezing the code, and fixing the functional changes to maintain secure NFT transactions.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/nft-defi.png" alt="NFT+DeFi" width={65} height={65} />
                                    <h3>NFT+DeFi</h3>
                                    <p>The combination of NFT with DeFi could deliver a cyber-secure environment with exceptional security. NFT Marketplace powered by DeFi protocols helps to boost liquidity and platform functionality leveraging the automated functionality with secure smart contract programming.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/nft-lending.png" alt="NFT Lending" width={65} height={65} />
                                    <h3>NFT Lending</h3>
                                    <p><a href="https://www.coinsclone.com/nft-lending-platform-development/" style={{color:"#006cfa"}}>NFT Lending Platform development</a> helps you realize the business model with massive benefits and investment capacity. Craft the features with an NFT lending solution that paves the way to increase your business reach and progress opening up a wide array of innovations.</p>
                                </div>
                            </Slider>
                            {/* <a href="https://www.coinsclone.com/nft-marketplace-development/" className="bluebtn mt-4 nft-btn">free demo</a> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="end"> 
                            <h3>Trading Bot Development</h3>
                            <p>Revolutionize your experience of trading with our <a href="https://www.coinsclone.com/cryptocurrency-trading-bot-development/">Trading Bot Development Services</a>. Our bots are developed using advanced algorithms to automate the trading process eliminating human intervention. We offer a wide range of trading bot development services including the development of Arbitrage Bots, Flash loan trading bots, and Sniper Bots.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="six">
                            <h3>Smart Contract Development</h3>
                            <p>Uncover the power of <a href="https://www.coinsclone.com/smart-contract-development-company/" style={{color:"#006cfa"}}>Smart Contract development</a> that is precisely supercharged with eminent blockchains like Ethereum, Polygon, Solana, and more to obtain efficiency and automation. Minimize the functional flaws, and enhance access and control with custom smart contract solutions.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="seven">
                            <h3>DApps</h3>
                            <p>Smart contract-powered DApp development could assist you in promoting enterprise standards keeping user privacy and platform simplicity. Revolutionize the way with custom <a href="https://www.coinsclone.com/dapp-development-company/" style={{color:"#006cfa"}}>DApp Development Solutions</a> specially created for business innovations that hold a broad layer of scalability and compatibility.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eight">
                            <Slider {...settings}>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/sto-ico.png" alt="STO/ICO Development" width={65} height={65} />
                                    <h3>STO/ICO Development</h3>
                                    <p>STO Development Company and <a href="https://www.coinsclone.com/ico-development-company/" style={{color:"#006cfa"}}>ICO Development Company</a> aims to satisfy startups’ fundraising goals more quickly and effortlessly with top-rated STO/ICO campaigns. STO/ICO platform development helps you offer graded security, efficiency, and transparency which are beneficial factors for marketing and promotional activities.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/stable-coin.png" alt="Stablecoin Development" width={65} height={65} />
                                    <h3>Stablecoin Development</h3>
                                    <p><a href="https://www.coinsclone.com/stablecoin-development-service/" style={{color:"#006cfa"}}>Stablecoin Development</a> Company maximizes your benefits on crypto investment without worrying about price volatility and market surges. Stablecoin development’s custom solution comes with next-generation features and protective measures making them compatible for different business applications.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/blockchain/crypto-token.png" alt="Crypto Token Development" width={65} height={65} />
                                    <h3>Crypto Token Development</h3>
                                    <p>As a premier Blockchain development company, we offer the best <a href="https://www.coinsclone.com/crypto-token-development/" style={{color:"#006cfa"}}>Crypto Token Development services</a>.  Our full-fledged crypto tokens are tailored to serve diverse applications as per industry norms. Explore endless possibilities with fungible and non-fungible tokens that are well-calibrated for specific use cases and applications as per business conditions.</p>
                                </div>
                            </Slider>
                        </Tab.Pane>
                        
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <div className="mt-1 pt-lg-1 text-center d-none d-xl-block">
                <a href="/contact-us/" className="bluebtn mt-3 mx-auto d-table">Hire Our Blockchain Developers</a>
            </div>
          </div>
      </section>

    )
  }
}

export default WeFocused
