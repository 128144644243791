import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is Blockchain and how it work?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Blockchain is an immutable, common distributed database that acts like a digital ledger to store transactions using cryptographic technologies called blocks. Each transaction is stored in a block, which is linked to a series of blocks to form a blockchain, making them secure and transparent.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                Do you provide Blockchain project Development Consulting?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Yes, Coinsclone provides Blockchain project development consulting services. We have a team of experienced consultants who offer consultation including project planning, and feature integration strategies, to ensure the project’s success from ideation to deployment.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How do you hire the right Blockchain Development Company?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Hiring a blockchain development company like Coinsclone is essential and crucial for your project's success. First, consider your project requirements and budget and do some in-depth research on the company’s portfolio, technical capability, and success rate to choose the best blockchain development company that aligns with your requirements.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How much does it cost to hire Blockchain Developers?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Hiring blockchain developers from a reputed company would cost around $30 - $100 per hour. Moreover, this average cost might vary according to your project requirements and the complexities involved.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How long does it take to build a Blockchain App?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Building a blockchain app with our custom solution usually takes one to two weeks. However, this is an approximate time frame and will vary depending on project requirements and the complexity of the project.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                What Are the Core Features of Blockchain Technology?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">The core features of blockchain technology include immutability, decentralization, transparency, Self Automation with Smart Contracts, Anonymity, Privacy, and much more.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle"  eventKey="6">
                How much do blockchain development services cost?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Generally, the basic blockchain development services cost ranges between $30,000 to $50,000. However, we can not predict the cost exactly as it varies according to the blockchain development company and various services like a crypto exchange, wallet, and payment gateway creation.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection